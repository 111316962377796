
import React,{useState,useEffect} from 'react'
import axios  from 'axios';
export default function Upload() {
  const [tab,setTab]=useState("ADD PRODUCT")
  const [tabList,setTabList]=useState(["ADD PRODUCT","ADD CATEGORY"])
  const [img2, setImg2] = useState("");
  const [file, setFile] = useState("");
  const [urld, setUrl] = useState("");


  const [category, setCategory] = useState("");
  const [productName, setproductName] = useState("");

  const [productDescription, setproductDescription] = useState("");

  const [productPrice, setproductPrice] = useState("");
  const [quantity, setquantity] = useState("");


  
  const [nav,setNav]=useState([])

  useEffect(()=>{


    axios.get(`http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3001/categorylist`)
      .then(res => {
        const persons = res.data;
        console.log(persons)
        setNav( persons );
      })

  },[])

 
  const addcategory= async()=> {
    // POST request using axios with async/await
    const article = {
      
      category: category,

      userEmail:'sppl.it2@gmail.com'};
    const response = await axios.post('http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3001/addcategory', article);
    this.setState({ articleId: response.data.id });
}
  const hit = async (file) => {
    const { url } = await fetch("http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3001/s3Url").then((res) =>
      res.json()
    );
    console.log("setUrlsetUrlsetUrlsetUrl",url);
    setUrl(url)

    if(url){


   const daat=  await axios.post('http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3001/imgurls',{  
     userEmail: 'sppl.it2@gmail.com',
   imagurl: url,

  
  
   category:category,
   productName:productName,
  
   productDescription:productDescription,
   productPrice:productPrice,
   quantity:quantity


   } )
      

   console.log(daat);
    }

    // post the image direclty to the s3 bucket
    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body:file ,
    });
  };
  useEffect(() => {}, [file]);

  return (
    <>
   {tab=="ADD PRODUCT" &&  <div className='flex items-center justify-center'>{file && <img src={file} alt="" /> }</div>}


    <div className="flex flex-column min-vh-60 w-100 flex items-center justify-center">
   

<div className="flex flex-column w-50 items-center flex-col justify-center">
  
<div className="flex w-100 mv3 items-center justify-center fw7">DATA ENTRY FORM</div>
<div className="flex w-100  items-center justify-center ">
{tabList.map((item)=>{return ( <div 
onClick={()=>setTab(item)}
className={`flex  items-center justify-center pointer h2 w-20 mh2  br-pill white ${tab!==item?'bg-black':'bg-green'} `}>{item}</div>

)})}

    </div>

{tab=="ADD PRODUCT" && <div className='w-100 flex  items-center flex-column justify-center  '>
 
<input className=" w-100 flex mv2 h2" type="text" 
onChange={(e)=>setproductName(e.target.value)} placeholder="Product Name"
value={productName}
/>
<select className=" w-100 flex mv2 h2" 

onChange={(e)=>setCategory(e.target.value)}
>
 {nav.map((item)=><option selected value={item.categoryName}>{item.categoryName}</option>)} 
 
</select>

<input className="w-100 flex mv2 h2" type="text" placeholder="Product decription"
onChange={(e)=>setproductDescription(e.target.value)}
value={productDescription}
/>

<input className="w-100 flex mv2 h2" type="text" placeholder="Product Price"

value={productPrice}

onChange={(e)=>setproductPrice(e.target.value)}

/>

<input className="w-100 flex mv2 h2" type="text"

value={quantity}

placeholder="Quantity Available"
onChange={(e)=>setquantity(e.target.value)}

/>

{/* <input className="w-100 flex mv2 h2" type="text"  /> */}
<input
className='w-100 flex mv2 h2'
        type="file"
        onChange={(event) =>
          event.target.files &&
          (setImg2(event.target.files[0]),
          setFile(URL.createObjectURL(event.target.files[0])))
        }
      />

<div className="flex w-full">

  {urld}
<button className='flex bg-blue white pa3 br2 b--white' onClick={(event) => hit(img2)}>SUBMIT</button>
</div>



<div>
      {/* <input
        type="file"
        onChange={(event) =>
          event.target.files &&
          (setImg2(event.target.files[0]),
          setFile(URL.createObjectURL(event.target.files[0])))
        }
      /> */}


   
    
     
    </div>
    </div>}



    {tab=="ADD CATEGORY" && <div className='w-100 flex  items-center flex-column justify-center  '>
    <input className="w-100 flex mv2 h2" type="text" placeholder="Product decription"  />

<input className="w-100 flex mv2 h2" 
value={category}
onChange={(e)=>setCategory(e.target.value)}
type="text" placeholder="CATEGORY Nmae"  />

<button className='flex bg-blue white pa3 br2 b--white' onClick={(event) => addcategory()}>SUBMIT</button>
</div>

    }
</div>

    </div>
    </>
  )
}



// import React, { useState, useEffect } from "react";
// import axios from 'axios'
// export default function Upload() {
//   const [img2, setImg2] = useState("");
//   const [file, setFile] = useState("");

//   const hit = async (file) => {
//     const { url } = await fetch("http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3001/s3Url").then((res) =>
//       res.json()
//     );
//     console.log(url);

//     if(url){


//    const daat=  await axios.post('http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3001/imgurls',{  
//      userEmail: 'sppl.it2@gmail.com',
//    imagurl: url,
//    likes: 0,

//    } )
      

//    console.log(daat);
//     }

//     // post the image direclty to the s3 bucket
//     await fetch(url, {
//       method: "PUT",
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//       body:file ,
//     });
//   };
//   useEffect(() => {}, [file]);

//   return (
//     <div>
//       <input
//         type="file"
//         onChange={(event) =>
//           event.target.files &&
//           (setImg2(event.target.files[0]),
//           setFile(URL.createObjectURL(event.target.files[0])))
//         }
//       />
//    <img src="s3://mice-img/6fe0196e299098c85177593ffa5a7281" alt="" />

//       <button onClick={(event) => hit(img2)}>HIt</button>
//       {file && <img src={file} alt="" /> && <p>{file}</p>}
//       <p>sss</p>
//     </div>
//   );
// }
