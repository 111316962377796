// import React from "react";
import { Link } from "react-router-dom";
import Logo from '../logo.png'
import Cart from'../cart.png'
import React,{useEffect,useState} from "react";
import axios from 'axios'

export default function Header() {

  const [nav,setNav]=useState([])


  useEffect(()=>{


    axios.get(`http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3001/categorylist`)
      .then(res => {
        const persons = res.data;
        console.log(persons)
        setNav( persons );
      })

  },[])
  let arr = [
    {
      name: "Home",
      icon: "",
      path: "/",
    },
    
    {
      name: "trophies",
      icon: "",
      path: "/video",
    },
    {
      name: "fragrance",
      icon: "",
      path: "/sss",
    },
    {
      name: "wfh-kit",
      icon: "",
      path: "/sss",
    },
    {
      name: "hs-kit",
      icon: "",
      path: "/sss",
    },
    {
      name: "hs-kit",
      icon: "",
      path: "/sss",
    },
    {
      name: "hs-kit",
      icon: "",
      path: "/sss",
    },
    {
      name: "conference-",
      icon: "",
      path: "/sss",
    },
    {
      name: "conference-kit",
      icon: "",
      path: "/sss",
    },
    {
      name: "conference-kit",
      icon: "",
      path: "/sss",
    },
    {
      name: "conference-kit",
      icon: "",
      path: "/sss",
    },
    {
      name: "conference-kit",
      icon: "",
      path: "/sss",
    },

  ];
  return (
    <div className="ph6 pv2 bg-white  flex w-100  shadow-4 flex items-start justify-start z-max top-0" style={{position: "fixed"}}>
<div ><img
onClick={()=>window.location=`/`}
className="w4  h3" src={Logo} alt="" /></div>

      <div className="ph2  pv4 flex items-start justify-end w-100">
        {nav.map((item) => {
          return (
              <div className="ph3 fw4 f6 black ttu lh-copy pointer" onClick={()=>window.location=`/${item.categoryName}`}  >
            {item.categoryName}
            </div>
         
          );
        })}
        <img src={Cart} className="h2 pointer" alt="" />
      </div>
     {/* <Users/> */}
    
    </div>
  );
}


