import React,{useEffect,useState} from "react";
// import AwsSliderStyles from 'react-awesome-slider/src/styles.scss';
import AwesomeSlider from "react-awesome-slider";
import SimpleImageSlider from "react-simple-image-slider";
import axios from 'axios'
export default function Homepage() {

  const [nav,setNav]=useState(0)


  useEffect(()=>{


    axios.get(`https://jsonplaceholder.typicode.com/users`)
      .then(res => {
        const persons = res.data;
        // this.setState({ persons });
      })
  },[])


  const Slider = (
    <AwesomeSlider>
      <div data-src="https://gift.virtuallive.in/wp-content/uploads/2020/11/shubharambh-office-essentials-14.png" />
      <div data-src="https://gift.virtuallive.in/wp-content/uploads/2020/11/shubharambh-office-essentials-14.png" />
      <div data-src="https://gift.virtuallive.in/wp-content/uploads/2020/11/shubharambh-office-essentials-14.png" />
    </AwesomeSlider>
  );

  const images = [
    {
      url: "https://gift.virtuallive.in/wp-content/uploads/2020/10/t-voucher.png",
    },
    {
      url: "https://gift.virtuallive.in/wp-content/uploads/2020/10/food-voucher.png",
    },
    {
      url: "https://gift.virtuallive.in/wp-content/uploads/2020/11/shubharambh-office-essentials-13-2048x1200.png",
    },
  ];

  return (
    <div className="flex flex-column w-100 items-center justify-center mt6">
      <div className="w-100 items-center justify-center  flex">
        <div className="flex w-30 bg-red mh2">
          <img
            src="https://gift.virtuallive.in/wp-content/uploads/2020/10/t-voucher.png"
            alt=""
          />
        </div>
        <div className="flex w-30 bg-green mh2">
          <img
            src="https://gift.virtuallive.in/wp-content/uploads/2020/11/shubharambh-office-essentials-13-2048x1200.png"
            alt=""
          />
        </div>
        <div className="flex w-30 bg-blue mh2">
          <img
            src="https://gift.virtuallive.in/wp-content/uploads/2020/10/food-voucher.png"
            alt=""
          />
        </div>
      </div>

      <div className="flex items-center justify-center w-100 mt3">
        <div className="w-25  flex flex-column h4 bw1 ba b--gray flex bw1  tc items-center justify-center ">
   <p className="fw6"> Delivery Time</p>  
   <p>2-10 Days</p>

        </div>
        <div className="w-25 flex-column  h4 flex bw1 b--gray bt bb  items-center justify-center ">
    <p className="fw6">      Delivery Location </p>
    <p>2-10 Days</p>
        </div>
        <div className="w-25 flex-column  h4 flex  bw1 b--gray bl bt bb items-center justify-center ">
        <p className="fw6">     Discounts </p>
        <p>2-10 Days</p>
        </div>
        <div className="w-25 flex-column  h4 flex  ba bw1 b--gray items-center justify-center ">
        <p className="fw6">     Payment </p>
        <p >2-10 Days</p>
        </div>
      </div>
      <div className="flex  items-center justify-center w-100 mt3">
        <div className="w-60 flex items-center justify-center mh2 ">
          <SimpleImageSlider
            width={950}
            height={650}
            images={images}
            showBullets={true}
            showNavs={true}
          />
        </div>
        <div className="w-30  mh2  flex flex-column ">
          <img
            className="mv1 w-100"
            src="https://gift.virtuallive.in/wp-content/uploads/2020/11/commertial-page.jpg"
            alt=""
          />
          <img
            className="mv1"
            src="https://gift.virtuallive.in/wp-content/uploads/2020/11/shubharambh-office-essentials-14.png"
            alt=""
          />
        </div>

        {/* <Slider/> */}
      </div>
    </div>
  );
}
