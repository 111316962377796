import React, { useEffect, useState } from "react";
import ReactToPrint from "react-to-print";
// import { useState } from 'react/cjs/react.development';
// import TableComponent from './table.component';
import "./tachyons/css/tachyons.min.css";
// import '../tachyons/css/swiper.min.css';
import "./tachyons/css/style.css";
import "./App.css";
import Home from "./pages/home";
import Logo from "./logo.png";
import {
  BrowserRouter,
  Routes,Link,
  Route
} from "react-router-dom";
import Header from "./common/header";
import Trophies from './common/trophies'
import Addtocart from "./common/addtocart";
import HomePage from "./pages/homepage"
import Uploader from "./pages/upload"
import Preview from './pages/preview'
import IFrame from './pages/iframe'
import Zoom from './pages/zoom'
import axios from 'axios'
import SelectHere from './pages/select'
import AutoPlayVideo from './pages/AutoPlayVideo'
import Baic from './pages/newselect'
class ExportPdfComponent extends React.Component {
  state = {
    arr: 1,
  };

  render() {
    let ss = new Array(this.state.arr).fill("1");
    console.log("4567890", ss, this.state);
    return (
      <div className="w-100 flex  flex-column items-center justify-center">
        <button onClick={() => this.setState({ arr: this.state.arr + 1 })}>
          ADD ROW
        </button>
        <button onClick={() => this.setState({ arr: this.state.arr - 1 })}>
          {this.state.arr}
        </button>
        <h1>Export HTMl Table in PDF File</h1>

        <div className="w-80 flex items-center justify-center">
          <TableComponent
            arr={ss}
            ref={(response) => (this.componentRef = response)}
          />
        </div>

        <ReactToPrint
          content={() => this.componentRef}
          trigger={() => (
            <button className="btn btn-primary">Print to PDF!</button>
          )}
        />
      </div>
    );
  }
}

//  ExportPdfComponent;

// import React from 'react'

const App222 = () => {
  const arr = ["1"];
  const [row, setRow] = useState(new Array(count));
  const [count, setCount] = useState(1);

  useEffect(() => {
    setRow(arr);
    return () => {};
  }, [count]);

  return (
    <div onClick={() => (arr.push("1"), setCount(count + 1))}>
      ADD row {count}

    
    </div>
  );
};

class TableComponent extends React.Component {
  state = {
    datafirst: [],
  };
  render() {
    return (
      <div className="w-100   flex  flex-column  ">
        <div className="w-100   flex  flex-column items-center justify-center  ">
          <div className="flex w-100  ph3 mt3">
            <div className="w-30  flex item-start pl2 ">
              <img className="h2-3 " src={Logo} alt="" />{" "}
            </div>
            <div className="w-70 flex flex-column items-end justify-end ">
              <div className="flex w-100 items-end justify-end ">
                address line1{" "}
              </div>
              <div className="flex items-end justify-end  w-100 ">
                address line2{" "}
              </div>

              <div className="flex items-end justify-end  w-100 ">
                address line3{" "}
              </div>

              <div className="flex items-end justify-end  w-100 ">
                address line4{" "}
              </div>

              <div className="flex items-end justify-end  w-100 ">
                address line5{" "}
              </div>

              <div className="flex items-end justify-end  w-100 ">
                address line6{" "}
              </div>
            </div>
          </div>

          <div className="h1 flex flex-column w-100 ph3  mb1">
            <div className="bg-gray flex items-center justify-center w-100  ">
              <div className=" w-60 ba ">
                <input
                  className="w-100  inputbill  ml2 black"
                  value={this.state.datafirst.qutaionNo}
                  onChange={(e) =>
                    this.setState({
                      datafirst: {
                        ...this.state.datafirst,
                        qutaionNo: e.target.value,
                      },
                    })
                  }
                  placeholder="Quation No : sppl /2021-22/241"
                  type="text"
                  name="sss"
                  id=""
                />
              </div>
              <div className="  br bt bb  w-50">
                <input
                  onChange={(e) =>
                    this.setState({
                      datafirst: {
                        ...this.state.datafirst,
                        qutaion: e.target.value,
                      },
                    })
                  }
                  className="w-100 inputbill ml2"
                  type="text"
                  placeholder="Quation no : sppl /2021-22/241"
                  name="sss"
                  id=""
                />{" "}
              </div>
            </div>
          </div>

          <div className=" flex flex-column w-100 ph3  ">
            <div className=" flex items-center justify-center w-100  ">
              <div className=" w-60 bt bl br ">
                <input
                  onChange={(e) => this.setState({ datafirst: "ssss" })}
                  className="w-100  inputbill  ml2 black"
                  placeholder="Quation No : sppl /2021-22/241"
                  type="text"
                  name="sss"
                  id=""
                />
              </div>
              <div className="  br bt  w-50">
                <input
                  className="w-100 inputbill ml2"
                  type="text"
                  placeholder="Quation no : sppl /2021-22/241"
                  name="sss"
                  id=""
                />{" "}
              </div>
            </div>
          </div>

          <div className=" flex flex-column w-100 ph3  ">
            <div className=" flex items-center justify-center w-100  ">
              <div className=" w-60 bt bb bl br ">
                <input
                  onChange={(e) => this.setState({ datafirst: "ssss" })}
                  className="w-100  inputbill  ml2 black"
                  placeholder="Quation No : sppl /2021-22/241"
                  type="text"
                  name="sss"
                  id=""
                />
              </div>
              <div className="  br bt bb w-50">
                <input
                  className="w-100 inputbill ml2"
                  type="text"
                  placeholder="Quation no : sppl /2021-22/241"
                  name="sss"
                  id=""
                />{" "}
              </div>
            </div>
          </div>

          {/* above section */}

          <div className=" flex flex-column  w-100 ph3  ">
            <div className="  bg-gray flex  f7 items-center justify-center w-100  ">
              <div className=" flex  items-center justify-center  br bb bl w-10">
                OTY
              </div>

              <div className=" flex  items-center justify-center  br bb  w-50">
                PARTICLUARS
              </div>
              <div className=" flex   w-50 bb">
                <div className=" flex  items-center justify-center  br  w-100">
                  OTY
                </div>
                <div className=" flex   items-center justify-center br   w-100">
                  RATE
                </div>

                <div className=" flex  items-center justify-center br  w-100">
                  DAYS
                </div>

                <div className=" flex  items-center justify-center br  w-100">
                  AMOUNT
                </div>
              </div>
            </div>
          </div>
          {/* no of items */}

          <div className=" flex flex-column w-100 ph3  ">
            <div className="b1 bg-gray flex items-center justify-center w-100  ">
              <div className=" w-10 flex items-center justify-center tc  bl br ">
                <input
                  className="w-100 tc   inputbill  ml2 black"
                  placeholder=""
                  type="text"
                  name="sss"
                  id=""
                />
              </div>
              <div className=" w-50   br ">
                <input
                  className="w-100  inputbill  ml2 black"
                  placeholder=""
                  type="text"
                  name="sss"
                  id=""
                />
              </div>
              <div className="   w-50">
                <span className="ml2 f7"> Particular</span>
              </div>
            </div>
          </div>

          {/* no of colmnn */}
          {this.props.arr.map((item)=>{return( <div className=" flex  flex-column  w-100 ph3  ">
            <div className="  bg-gray flex   f7 items-center justify-center w-100  ">
              <div className=" flex  items-center justify-center  br bb bl w-10">
                <input
                  className="w-100 tc h2   inputbill  ml2 black"
                  placeholder=""
                  type="text"
                  name="sss"
                  id=""
                />
              </div>

              <div className=" flex  items-center justify-center  br bb  w-50">
                <input
                  className="w-100 tc  h2  inputbill  ml2 black"
                  placeholder=""
                  type="text"
                  name="sss"
                  id=""
                />
              </div>
              <div className=" flex   w-50 bb">
                <div className=" flex  items-center justify-center  br  w-100">
                  <input
                    className="w-100 tc  h2   inputbill  ml2 black"
                    placeholder=""
                    type="text"
                    name="sss"
                    id=""
                  />
                </div>
                <div className=" flex   items-center justify-center br   w-100">
                  <input
                    className="w-100 tc h2   inputbill  ml2 black"
                    placeholder=""
                    type="text"
                    name="sss"
                    id=""
                  />
                </div>

                <div className=" flex  items-center justify-center br  w-100">
                  <input
                    className="w-100 tc h2  inputbill  ml2 black"
                    placeholder=""
                    type="text"
                    name="sss"
                    id=""
                  />
                </div>

                <div className=" flex  items-center justify-center br  w-100">
                  <input
                    className="w-100 tc h2  inputbill  ml2 black"
                    placeholder=""
                    type="text"
                    name="sss"
                    id=""
                  />
                </div>
              </div>
            </div>
          </div>)}) 
         
          }
        </div>

        <div className="w-100   flex  flex-column items-center justify-center  ">
          <div className="flex w-100  ph3"></div>
        </div>
      </div>
    );
  }
}




export default function App() {
  const [nav,setNav]=useState([])

  useEffect(()=>{


    axios.get(`http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3001/categorylist`)
      .then(res => {
        const persons = res.data;
        console.log(persons)
        setNav( persons );
      })

  },[])
  return (
    <div className="" >


      
      <BrowserRouter>
     
      <Header/>
      <div className=" ">
      
    <Routes>
    {/* <Route path="/" element={<Baic />} />  */}
    { nav &&nav.map((item) => {  return (item.categoryName!=="upload" && <Route exact path={`/${item.categoryName}`} element={<Trophies  api={item.categoryName} />} />);
        })}
          <Route exact path="/upload" element={<Uploader />} />
          {/* <Route path="/sss" element={<ExportPdfComponent />} /> */}
      {/* <Route path="/" element={<ExportPdfComponent />} /> */}
      {/* <Route path="/SelectHere" element={<SelectHere />} /> */}
      {/* <Route path="/" element={<HomePage />} />  */}
      <Route path="/" exact element={<HomePage />} /> 
{/* 
      <Route path="/homepage" element={<HomePage />} />
      <Route path="/trophies" element={<Trophies />} />
      <Route path="/Addtocart" element={<Addtocart />} />
      <Route path="/upload" element={<Uploader />} />
      <Route path="/preview" element={<Preview />} />
      <Route path="/video" element={<IFrame />} />
      <Route path="/zoom" element={<Zoom />} />
      <Route path="/autoplay" element={<AutoPlayVideo />} /> */}

     
      {  console.log("  console.log(persons)  console.log(persons)",nav)}
     
    </Routes>
    </div>
  </BrowserRouter>,
    </div>
  )
}
