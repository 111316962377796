import React, { useEffect, useState } from "react";

class WrappedFrame extends React.Component {
    state = { contentHeight: 100 };
  
    handleResize = () => {
      const { body, documentElement } = this.container.contentWindow.document;
      const contentHeight = Math.max(
        body.clientHeight,
        body.offsetHeight,
        body.scrollHeight,
        documentElement.clientHeight,
        documentElement.offsetHeight,
        documentElement.scrollHeight
      );
      if (contentHeight !== this.state.contentHeight) this.setState({ contentHeight });
    };
    
    onLoad = () => {
        // alert(8)
      this.container.contentWindow.addEventListener('resize', this.handleResize);
      this.handleResize();
    }
    
    componentWillUnmount() {
      this.container.contentWindow.removeEventListener('resize', this.handleResize);
    }
    
    render() {
        // alert(window.innerWidth)
      const { contentHeight } = this.state;
      document.body.style.overflow = 'hidden';
      return (
        <iframe
          frameBorder="0"
          onLoad={this.onLoad}
          ref={(container) => { this.container = container; }}
          scrolling="no"
          autoPlay='true'
          allow='autoplay'
          src="https://player.vimeo.com/video/679412773?rel=0;&controls=0loop=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media&amp;autoplay=1"
          style={{ width: `100%`, height: '125vh' }}
          title="Some Content"
        />
      );
    }
  }

  export default WrappedFrame