import React, { useState } from "react";

const initialState = { alt: "", src: "" };

const AddNew = () => {
  const [{ alt, src }, setPreview] = useState(initialState);
  const [imgdata,setImgData]=useState(initialState)

  const fileHandler = event => {
    const { files } = event.target;

    setImgData(event.target.files[0])
    setPreview(
      files.length
        ? {
            src: URL.createObjectURL(files[0]),
            alt: files[0].name
          }
        : initialState
    );
  };

  const  hit=async(file)=>{    
    const { url } = await fetch("http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3001/s3Url").then(res => res.json())
  console.log(url)

  // post the image direclty to the s3 bucket
  await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    body: file
  })
}

  return (
    <div className="addNew flex flex-column w-100 items-center justify-center  min-vh-100">
        {src &&<img className="preview h6 w6" src={src} alt={alt} />}
<div className="shadow-4   flex-column flex w-50">
        <div className="flex w-100 items-center justify-center ">
            <p className="f5 fw6 ttu">product add </p>

            
        </div>

        <div className="flex flex-column w-100  items-center justify-center">

            <div className="w-90  flex items-center justify-center">
                <input className="w-100 h2" placeholder="product name" /> </div>


            <div className="w-90 mv2  flex items-center justify-center">
                <textarea className="w-100 " placeholder="product decription" /> </div>
                <div className="w-90 mv2  flex items-center justify-center">
                <input className="w-30  h2 mh2" placeholder="price" />      
                  <input className="w-30 mh2 h2 " placeholder="category" />
                  
                  <input className="w-30 mh2 h2 " accept="image/*" type="file" onChange={fileHandler} />
                  </div>

                  <div className="w-90 pa2  flex items-center justify-center">
               <button className="w-20"> Submit</button>
               </div>

        </div>
        </div>
      {/* <img className="preview" src={src} alt={alt} />
      <input accept="image/*" type="file" onChange={fileHandler} />
      <button onClick={hit(imgdata)}>sub2mit</button> */}
    </div>
  );
};

export default AddNew;
